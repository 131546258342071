<template>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" class="Screen">
        <div class="ScreenMain">
            <div class="screenTitle" v-if="!loading">
                <span><i class="iconfont icon-alarm"></i>实时感知异常</span>
                <span style="font-weight:bold;">{{todayNum}}</span>
                <span>
                    <van-button round type="danger" size="small" plain @click="toRealAnomaly">立即查看</van-button>
                </span>
            </div>
            <van-loading v-if="loading" type="spinner" style="text-align: center" />
            <ul class="screenList" v-if="!loading">
                <li v-for="(item,index) in columns" :key="index">
                    <div v-if="item.flag">
                        <span class="listTitle">{{item.days==0?'今日':item.days==1?'昨日':item.days==7?'7天内':'30天内'}}</span>
                        <van-row class="listBox">
                            <van-col span="6" style="height: 100%;">
                                <div class="listCell">异常邮件</div>
                                <div class="bold" @click="toSearchPage(item.days,'abnormal')">{{item.data.abnormalMailCount}}</div>
                            </van-col>
                            <van-col span="6" style="height: 100%;">
                                <div class="listCell">连接超时</div>
                                <div class="bold" @click="toSearchPage(item.days,'timeOutError')">{{item.data.connectTimeoutCount}}</div>
                            </van-col>
                            <van-col span="6" style="height: 100%;">
                                <div class="listCell">邮局踢出</div>
                                <div class="bold" @click="toSearchPage(item.days,'dropOutError')">{{item.data.dropOutCount}}</div>
                            </van-col>
                            <van-col span="6" style="height: 100%;">
                                <div class="listCell">密码错误</div>
                                <div class="bold" @click="toSearchPage(item.days,'authError')">{{item.data.authErrorCount}}</div>
                            </van-col>
                        </van-row>
                    </div>
                    <div v-else>暂无数据</div>
                </li>
            </ul>
        </div>
    </van-pull-refresh>
</template>
<script>
import titleMixin from '@/mixin/title'
export default {
    name: 'Screen',
    mixins: [titleMixin],
    title: '邮件接收监视器',
    data() {
        return {
            columns: [],
            count: 0,
            isLoading: true,
            loading: true
        }
    },
    mounted() {
        this.setMenu()
    },
    created() {
        Promise.all([this.getWatchData(0), this.getWatchData(1), this.getWatchData(7), this.getWatchData(30)]).then((result) => {
            this.loading = false
            this.columns = result
        }).catch((error) => {
            this.loading = false
            console.log(error)
        })
        // Promise.all([this.getWatchData(0)]).then((result) => {
        //     this.loading = false
        //     this.columns = result
        // }).catch((error) => {
        //     this.loading = false
        //     console.log(error)
        // })
    },
    computed: {
        todayNum: function () {
            if (this.columns[0] && this.columns[0].flag) {
                let total = this.columns[0].data.abnormalMailCount + this.columns[0].data.connectTimeoutCount + this.columns[0].data.dropOutCount + this.columns[0].data.authErrorCount
                return total
            }
        }
    },
    methods: {
        aa() {
            this.$router.push({
                path: '/mailmonitor/search'
            })
        },
        setMenu() {
            setTimeout(() => {
                let setMenu = this.Global.utils.rightMenu.setMenu('搜索')
                setMenu.onClick(() => {
                    this.aa()
                })
            }, 100)
        },
        onRefresh() {
            setTimeout(() => {
                Promise.all([this.getWatchData(0), this.getWatchData(1), this.getWatchData(7), this.getWatchData(30)]).then((result) => {
                    this.columns = result
                }).catch((error) => {
                    console.log(error)
                })
                this.isLoading = false
            }, 500)
        },
        toRealAnomaly() {
            this.$router.push('/mailmonitor/realanomaly')
        },
        toSearchPage(days, type) {
            this.$router.push({
                path: '/mailmonitor/search',
                query: {
                    days: days, type: type
                }
            })
        },
        async getWatchData(days) {
            let result = { flag: false }
            let params = {
                days: days
            }
            try {
                let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.mailWatcher_statistics, { params: params })
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    result = { flag: true, data: res.data.data, days: days }
                    // if (days == 0) {
                    //     result.name = '今日'
                    // } else if (days == 1) {
                    //     result.name = '昨日'
                    // } else if (days == 7) {
                    //     result.name = '7天内'
                    // } else if (days == 30) {
                    //     result.name = '30天内'
                    // }
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
            }
            return result
        }
    }
}
</script>
<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
<style lang='less' rel='stylesheet/less'>
.Screen .van-pull-refresh__track {
    height: 100%;
}
</style>
